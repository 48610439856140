<template>
  <div :class="[decorated ? '' : 'cos-links-clink']">
    <NuxtLink :href="href" :aria-label="label" :target="targeted" class="flex max-w-fit">
      <div class="flex items-center">
        <slot />
        <div v-if="icon !== 'none'" class="ms-2 min-w-4 max-w-4" :class="[iconColor, iconMarginStyle]">
          <CWidgetSvg :name="iconName" />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  label: string,
  href: string,
  icon?: 'Intern' | 'Extern' | 'Angle' | 'Arrow' | 'AngleUp' | 'AngleSmall' | 'ArrowUp' | 'ArrowUpLong' | 'ChevronUp' | 'none',
  iconColor?: string,
  decorated?: boolean,
  target?: '_blank' | '_self' | '_top' | '_parent',
}
const props = withDefaults(defineProps<Props>(), {
  icon: 'Intern',
  iconColor: 'text-sky-700',
  target: '_self',
})
const targeted = (props.icon == 'Extern' ? '_blank' : undefined) ?? props.target

const iconMap = {
  'Intern': 'link',
  'Extern': 'link_extern',
  'Angle': 'angle_right',
  'AngleSmall': 'angle_small',
  'Arrow': 'arrow_right_long',
  'AngleUp': 'angle_up',
  'ArrowUp': 'arrow_up',
  'ArrowUpLong': 'arrow_up_long',
  'ChevronUp': 'chevron_up',
  'none': '',
}

const iconMarginMap = {
  'Intern': '',
  'Extern': '',
  'Angle': '',
  'AngleSmall': '',
  'Arrow': '',
  'AngleUp': '',
  'ArrowUp': '',
  'ArrowUpLong': '',
  'ChevronUp': '',
  'none': '',
}
const iconName = iconMap[props.icon]
const iconMarginStyle = iconMarginMap[props.icon]
</script>
